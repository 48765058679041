<template>
  <div class="index">
    开发中...
  </div>
</template>


<script>
export default {
  name: 'Fail',

  data() {
    return {
      currentRoutePath: "/product"
    }
  },
  methods: {

  },

  //自动触发写入的函数
  mounted: function () {
  }
}

</script>

<style>

</style>