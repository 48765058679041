<template>
  <div style="font-size: 14px;line-height: 60px;">

    <!-- 顶部-->
    <div class="divHeader">
      <div style="font-weight:600"> 模板消息</div>
      <div style="display: flex;flex-direction: row;">
        <div>
          <el-button icon="el-icon-plus" @click="showAddTempMsg = true;proDataMsgFrom = {};proDataMsgFrom.msgFirstColor='#6c6c6c';
          keywordForm=[{'key':'keyword1','keyword': {'value': '', 'color': ''} }, { 'key': 'keyword2','keyword': {'value': '', 'color': ''}}];pointValue=null">
            添加
          </el-button>
        </div>
        <div style="margin-left: 10px;">
          <el-select v-model="miniAppId" placeholder="请选择APPID" @change="tempMsgSelectByTempId($event)"
                     style="width: 280px">
            <el-option v-for="item in proAppConfigList"
                       :key="item.miniAppId"
                       :label="item.miniAppId"
                       :value="item.miniAppId">
              <span style="float: left">{{ item.appName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.miniAppId }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div style="height: 1px;background-color:   rgb(215,215,215)"></div>

    <div v-if="proTemplateMsgList.length === 0" style="height: 150px;line-height: 150px;font-size: 16px">空</div>

    <!--    {{proTemplateMsgList}}-->
    <!-- 模板列表-->
    <div v-for="(pro,index) in proTemplateMsgList" :key="index"
         style="line-height: 35px;text-align: left;margin-top: 10px">
      <el-col :span="8" style="padding: 10px;">
        <div style="border-radius: 6px;padding: 10px;border: 1px solid   rgb(215,215,215);">
          <div v-for="(ins,indexs) in pro" :key="indexs">
            <div v-if="indexs !== 'templateId' & indexs !== 'msgId' & indexs !== 'keywordInfo'"
                 :style="{'color':ins.color}" style="width: 80%;">{{ indexs }}:<span
                style="margin-left: 10px">{{ ins.value }}</span>
            </div>
          </div>

          <el-button icon="el-icon-delete" @click="delTempIdMsg(pro)"
                     style="float: right;border: #FFFFFF;margin-right: -10px;margin-top: -35px"></el-button>
          <el-button icon="el-icon-edit" @click="modiTempIdMsgValue(pro); proDataMsgFrom.id = pro.msgId.value"
                     style="float: right;border: #FFFFFF;margin-right: -20px;margin-top: -35px"></el-button>
        </div>
      </el-col>
    </div>

    <!-- 添加模板id格式-->
    <el-dialog title="添加模板消息" :visible.sync="showAddTempMsg" width="50%" center>
      <!--    head-->
      <div style="display: flex;flex-direction: row;line-height: 64px;">
        <div style="margin-left: 70px">模板ID：
          <el-select v-model="proDataMsgFrom.templateId" placeholder="请选择已配置的模板ID" style="width: 576px">
            <el-option v-for="item in proAppConfigList"
                       :key="item.templateId"
                       :label="item.templateId"
                       :value="item.templateId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="display: flex;flex-direction: row;line-height: 60px;margin-left: 42px;">
        <div style="margin-left: 30px">数据点：
          <el-select v-model="proDataMsgFrom.keywordFormValue" value-key="keyword" placeholder="请设置数据点字段"
                     style="width: 250px">
            <el-option v-for="item in keywordForm"
                       :key="item.key"
                       :label="item.key"
                       :value="item.key">
            </el-option>
          </el-select>
        </div>
        <div style="margin-left: 20px">&nbsp;&nbsp;告警：
          <el-select v-model="proDataMsgFrom.msgType" placeholder="是否是告警信息" style="width: 250px">
            <el-option v-for="item in alarmTypeOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!--    first-->
      <div style="display: flex;flex-direction: row;justify-content: left;margin-left: 50px">
        <div style="display: flex;flex-direction: row;justify-content: left;">
          <div style="margin-left: 39px"><span>first：</span>
            <el-input v-model="proDataMsgFrom.msgFirst"
                      style="width: 250px;margin-right: 20px;margin-left: 4px" placeholder="first"></el-input>
          </div>
          <div style="">color：
            <el-input v-model="proDataMsgFrom.msgFirstColor" style="width: 250px;" disabled
                      placeholder="16进制颜色值">#000000
            </el-input>
          </div>
        </div>
        <!--空字段时显示添加-->
        <el-button v-if="keywordForm.length === 0" @click="addLineNull()" style="margin-left: 20px;border:#FFFFFF"
                   class="el-icon-circle-plus"></el-button>
      </div>
      <!--循环配置的字段-->
      <div v-for="(item, index) in keywordForm" :key="index"
           style="display: flex;flex-direction: row;justify-content:  left;margin-left:50px">
        <div v-if="'keyword'+(index + 1) !== proDataMsgFrom.keywordFormValue"
             style="display: flex;flex-direction: row;">
          <div> keyword{{ index + 1 }}：
            <el-input v-model="item.keyword.value" placeholder="keyword"
                      style="width: 250px;margin-right: 20px"></el-input>
          </div>
          <div> color：
            <el-input v-model="item.keyword.color" placeholder="16进制颜色值"
                      style="width: 250px;margin-right: 20px"></el-input>
          </div>
        </div>
        <div v-if="'keyword'+(index + 1) === proDataMsgFrom.keywordFormValue">
          <div style="display: flex;flex-direction: row;justify-content: left;">
            <div> keyword{{ index + 1 }}：
              <el-input v-model="proDataMsgFrom.msgTxt" placeholder="keyword"
                        style="width: 250px;margin-right: 20px"></el-input>
            </div>
            <div style="">color：
              <el-input v-model="proDataMsgFrom.msgTxtColor" placeholder="16进制颜色值"
                        style="width: 250px;"></el-input>
            </div>
          </div>
          <div style="display: flex;flex-direction: row;justify-content: left;">
            <div style="display: flex;flex-direction: row;justify-content: left;">
              <span>&nbsp;&nbsp;设置条件：</span>
              <el-select v-model="pointValue" placeholder="请选择" @change="selectChange($event)"
                         style="width: 250px;margin-left: 4px">
                <el-option v-for="item in proDataPointList"
                           :key="item.id"
                           :label="item.enName"
                           :value="item.id">
                  <span style="float: left">{{ item.enName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.dataType }}</span>
                </el-option>
              </el-select>
            </div>

            <div style="background-color: #ffffff;text-align: left;margin-left: 20px;width: 10px">
              <div v-if="currentPoint.dataType === '布尔值'"
                   style="display: flex;flex-direction: row;justify-content: left;">
                <div style="width: 100px;background-color: #ffffff">
                  <el-button>布尔值 =</el-button>
                </div>
                <div>
                  <el-select v-model="booleanOptionsValue" placeholder="请选择" style="width: 210px;">
                    <el-option v-for="item in booleanOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value" style="width: 210px">
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div v-if="currentPoint.dataType === '数值'"
                   style="display: flex;flex-direction: row;justify-content: left;">
                <div>
                  <el-select v-model="numOptionsValue" placeholder="请选择" style="width: 100px">
                    <el-option v-for="item in numOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div style="background-color: #ffffff">
                  <el-input v-model="proDataMsgFrom.numValue" style="width: 210px"></el-input>
                </div>
              </div>

              <div v-if="currentPoint.dataType === '枚举'"
                   style="display: flex;flex-direction: row;justify-content: left;">
                <div style="width: 100px;background-color: #ffffff">
                  <el-button>枚举值 =</el-button>
                </div>
                <div>
                  <el-select v-model="proDataMsgFrom.enumContent" placeholder="请选择" style="width: 210px">
                    <el-option v-for="item in currentPoint.enumValue.split(',')"
                               :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <el-button v-if="keywordForm.length === index+1"
                     @click="proDataMsgFrom.keywordFormValue = '';keywordForm.pop()"
                     class="el-icon-remove" style="border:#FFFFFF"></el-button>
          <el-button v-if="keywordForm.length === index+1" @click="addLine(index)"
                     style="border:#FFFFFF;margin-left: -20px"
                     class="el-icon-circle-plus"></el-button>
        </div>

      </div>
      <!--remark-->
      <div style="display: flex;flex-direction: row;justify-content:  left;margin-left: 50px">
        <div style="margin-left: 18px">remark：
          <el-input v-model="proDataMsgFrom.msgMark" placeholder="remark"
                    style="width: 250px;margin-right: 20px"></el-input>
        </div>
        <div>color：
          <el-input v-model="proDataMsgFrom.msgMarkColor" placeholder="16进制颜色值"
                    style="width: 250px;margin-right: 20px"></el-input>
        </div>
      </div>
      <!--      小程序-->
      <div style="display: flex;flex-direction: row;justify-content:  left;margin-left: 50px">
        <div style="margin-left: 37px">参数：
          <el-input v-model="proDataMsgFrom.miniValue" placeholder="pages/index/index?mac=123" :disabled="!checked"
                    style="width: 250px;margin-right: 20px"></el-input>
        </div>
        <div>
          <el-checkbox v-model="checked">小程序</el-checkbox>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddTempMsg = false" style="width: 120px">取消</el-button>
        <el-button type="primary" @click="addTempIdMsg()" style="width: 120px">确认</el-button>
      </span>
    </el-dialog>

    <!-- 修改模板id格式-->
    <el-dialog title="修改模板消息" :visible.sync="modiAddTempMsg" width="50%" center style="min-width: 1000px">
      <div style="display: flex;flex-direction: row;line-height: 64px;">
        <div style="margin-left: 70px">模板ID：
          <el-select v-model="proDataMsgFrom.templateId" placeholder="请选择已配置的模板ID" style="width: 576px">
            <el-option v-for="item in proAppConfigList"
                       :key="item.templateId"
                       :label="item.templateId"
                       :value="item.templateId">
            </el-option>
          </el-select>
        </div>
      </div>

      <div style="display: flex;flex-direction: row;line-height: 60px;margin-left: 42px;">
        <div style="margin-left: 30px">数据点：
          <el-select v-model="proDataMsgFrom.keywordFormValue" value-key="keyword" placeholder="请设置数据点字段"
                     style="width: 250px">
            <el-option v-for="item in keywordForm"
                       :key="item.key"
                       :label="item.key"
                       :value="item.key">
            </el-option>
          </el-select>
        </div>
        <div style="margin-left: 20px">&nbsp;&nbsp;告警：
          <el-select v-model="proDataMsgFrom.msgType" placeholder="是否是告警信息" style="width: 250px">
            <el-option v-for="item in alarmTypeOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!--    first-->
      <div style="display: flex;flex-direction: row;justify-content: left;margin-left: 50px">
        <div style="display: flex;flex-direction: row;justify-content: left;">
          <div style="margin-left: 39px"><span>first：</span>
            <el-input v-model="proDataMsgFrom.msgFirst"
                      style="width: 250px;margin-right: 20px;margin-left: 4px" placeholder="first"></el-input>
          </div>
          <div style="">color：
            <el-input v-model="proDataMsgFrom.msgFirstColor" style="width: 250px;" :disabled=true
                      placeholder="16进制颜色值"></el-input>
          </div>
        </div>
        <!--空字段时显示添加-->
        <el-button v-if="keywordForm.length === 0" @click="addLineNull()" style="margin-left: 20px;border:#FFFFFF"
                   class="el-icon-circle-plus"></el-button>
      </div>
      <!--循环配置的字段-->
      <div v-for="(item, index) in keywordForm" :key="index"
           style="display: flex;flex-direction: row;justify-content:  left;margin-left:50px">
        <div v-if="'keyword'+(index + 1) !== proDataMsgFrom.keywordFormValue"
             style="display: flex;flex-direction: row;">
          <div> keyword{{ index + 1 }}：
            <el-input v-model="item.keyword.value" placeholder="keyword"
                      style="width: 250px;margin-right: 20px"></el-input>
          </div>
          <div> color：
            <el-input v-model="item.keyword.color" placeholder="16进制颜色值"
                      style="width: 250px;margin-right: 20px"></el-input>
          </div>
        </div>
        <div v-if="'keyword'+(index + 1) === proDataMsgFrom.keywordFormValue">
          <div style="display: flex;flex-direction: row;justify-content: left;">
            <div> keyword{{ index + 1 }}：
              <el-input v-model="proDataMsgFrom.msgTxt" placeholder="keyword"
                        style="width: 250px;margin-right: 20px"></el-input>
            </div>
            <div style="">color：
              <el-input v-model="proDataMsgFrom.msgTxtColor" placeholder="16进制颜色值"
                        style="width: 250px;"></el-input>
            </div>
          </div>
          <div style="display: flex;flex-direction: row;justify-content: left;">
            <div style="display: flex;flex-direction: row;justify-content: left;">
              <span>&nbsp;&nbsp;设置条件：</span>
              <el-select v-model="pointValue" placeholder="请选择" @change="selectChange($event)"
                         style="width: 250px;margin-left: 4px">
                <el-option v-for="item in proDataPointList"
                           :key="item.id"
                           :label="item.enName"
                           :value="item.id">
                  <span style="float: left">{{ item.enName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.dataType }}</span>
                </el-option>
              </el-select>
            </div>

            <div style="background-color: #ffffff;text-align: left;margin-left: 20px;width: 10px">
              <div v-if="currentPoint.dataType === '布尔值'"
                   style="display: flex;flex-direction: row;justify-content: left;">
                <div style="width: 100px;background-color: #ffffff">
                  <el-button>布尔值 =</el-button>
                </div>
                <div>
                  <el-select v-model="booleanOptionsValue" placeholder="请选择" style="width: 210px;">
                    <el-option v-for="item in booleanOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value" style="width: 210px">
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div v-if="currentPoint.dataType === '数值'"
                   style="display: flex;flex-direction: row;justify-content: left;">
                <div>
                  <el-select v-model="numOptionsValue" placeholder="请选择" style="width: 100px">
                    <el-option v-for="item in numOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div style="background-color: #ffffff">
                  <el-input v-model="proDataMsgFrom.numValue" style="width: 210px"></el-input>
                </div>
              </div>

              <div v-if="currentPoint.dataType === '枚举'"
                   style="display: flex;flex-direction: row;justify-content: left;">
                <div style="width: 100px;background-color: #ffffff">
                  <el-button>枚举值 =</el-button>
                </div>
                <div>
                  <el-select v-model="proDataMsgFrom.enumContent" placeholder="请选择" style="width: 210px">
                    <el-option v-for="item in currentPoint.enumValue.split(',')"
                               :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <el-button v-if="keywordForm.length === index+1"
                     @click="proDataMsgFrom.keywordFormValue = '';keywordForm.pop()"
                     class="el-icon-remove" style="border:#FFFFFF"></el-button>
          <el-button v-if="keywordForm.length === index+1" @click="addLine(index)"
                     style="border:#FFFFFF;margin-left: -20px"
                     class="el-icon-circle-plus"></el-button>
        </div>

      </div>
      <!--remark-->
      <div style="display: flex;flex-direction: row;justify-content:  left;margin-left: 50px">
        <div style="margin-left: 18px">remark：
          <el-input v-model="proDataMsgFrom.msgMark" placeholder="remark"
                    style="width: 250px;margin-right: 20px"></el-input>
        </div>
        <div>color：
          <el-input v-model="proDataMsgFrom.msgMarkColor" placeholder="16进制颜色值"
                    style="width: 250px;margin-right: 20px"></el-input>
        </div>
      </div>

      <!--      小程序-->
      <div style="display: flex;flex-direction: row;justify-content:  left;margin-left: 50px">
        <div style="margin-left: 37px">参数：
          <el-input v-model="proDataMsgFrom.miniValue" placeholder="pages/index/index?mac=123" :disabled="!checked"
                    style="width: 250px;margin-right: 20px"></el-input>
        </div>
        <div>
          <el-checkbox v-model="checked">小程序</el-checkbox>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
            @click="modiAddTempMsg = false;"
            style="width: 120px">取消</el-button>
        <el-button type="primary" @click="modiAddTempMsgHand()" style="width: 120px">确认</el-button>
      </span>
    </el-dialog>

  </div>
</template>


<script>
export default {
  name: 'protemplatemassage',

  data() {
    return {
      proAppConfigList: [], // 产品配置列表
      proTemplateMsgList: [], // 模板字段配置表
      proDataPointList: [],// 数据点表
      pointValue: "",//
      checked: false,
      inTemplateId: "", // 搜的模板id
      showAddTempMsg: false, // 显示添加弹框
      modiAddTempMsg: false,
      miniAppId: "",
      currentPoint: {
        dataType: ""
      },
      keywordForm: [ // 默认两个字段
        {
          key: "keyword1",
          keyword: {value: "", color: ""}
        },
        {
          key: "keyword2",
          keyword: {value: "", color: ""}
        }
      ],

      alarmTypeOptions: [
        {
          value: '0',
          label: '0'
        }, {
          value: '1',
          label: '1'
        }],
      proDataMsgFrom: {
        id: null,
        productKey: null,
        templateId: null,
        name: null,
        enName: null,
        dataType: null,
        booValue: null,
        enumValue: null,
        enumContent: null,
        numContent: null,
        numValue: null,
        msgType: null,
        msgFirst: null,
        msgFirstColor: null,
        keywordForm: [],
        keywordFormValue: "", // // 数据点字段
        msgTxt: null,
        msgTxtColor: null,
        msgMark: null,
        msgMarkColor: null,
        gomini: '0',
        miniValue: null,
      },
      numOptions: [{
        value: '1',
        label: '大于'
      }, {
        value: '2',
        label: '等于'
      }, {
        value: '3',
        label: '小于'
      }],
      numOptionsValue: "",
      booleanOptions: [{
        value: '0',
        label: '0'
      }, {
        value: '1',
        label: '1'
      }],
      booleanOptionsValue: "0",
      goMiniValue: "",
    }
  },

  methods: {
    // 提交修改
    modiAddTempMsgHand() {
      let proDataMsgFrom = this.proDataMsgFrom;
      proDataMsgFrom.keywordForm = this.keywordForm;
      proDataMsgFrom.productKey = this.proDetail.productKey;
      if (this.numOptionsValue === "1") {
        proDataMsgFrom.numContent = "大于";
      }
      if (this.numOptionsValue === "2") {
        proDataMsgFrom.numContent = "等于";
      }
      if (this.numOptionsValue === "3") {
        proDataMsgFrom.numContent = "小于";
      }
      proDataMsgFrom.booValue = this.booleanOptionsValue;
      proDataMsgFrom.gomini = "0";
      if (this.checked) {
        proDataMsgFrom.gomini = "1";
      }
      console.log("修改参数this.proDataMsgFrom:", proDataMsgFrom);
      if (proDataMsgFrom.productKey === undefined || proDataMsgFrom.templateId === undefined
          || proDataMsgFrom.keywordFormValue === undefined || proDataMsgFrom.msgType === undefined
          || proDataMsgFrom.msgFirst === undefined || proDataMsgFrom.msgFirstColor === undefined || proDataMsgFrom.msgMark === undefined
          || proDataMsgFrom.msgMarkColor === undefined) {
        this.$message.error('输入不能为空');
        return;
      }
      this.$axios.post(this.url + "/proTempIdMsg/modiProTempIdMsg", proDataMsgFrom).then(res => {
        console.log("modiProTempIdMsg res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.modiAddTempMsg = false;
          this.$message({message: '修改成功', type: 'success'});
          this.refreshTempIdMsg();
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    // 点击修改，页面输入框赋值
    modiTempIdMsgValue(pro) {
      this.keywordForm = [{'key': 'keyword1', 'keyword': {'value': '', 'color': ''}}, {
        'key': 'keyword2',
        'keyword': {'value': '', 'color': ''}
      }]
      this.modiAddTempMsg = true;
      let proDataMsgFrom = this.proDataMsgFrom;
      proDataMsgFrom.templateId = pro.templateId.value;
      let split = pro.msg.value.split(" ");// enName、条件、value
      console.log("pro", pro)
      this.pointValue = split[0];

      // keyword总长度 数据点位置 报警信息
      let splitKey = pro.keywordInfo.value.split(",");
      this.proDataMsgFrom.keywordFormValue = "keyword" + splitKey[1];
      this.proDataMsgFrom.msgType = splitKey[2];
      for (let i = 0; i < splitKey[0] - 2; i++) {
        this.addLine(i + 1);
      }
      if (pro.mini.value.length === 2) {
        this.checked = false;
      } else {
        this.checked = true
        this.proDataMsgFrom.miniValue = pro.mini.value.substring(2);
      }
      // proDataMsgFrom.msgTxt
      for (let i = 1; i <= this.keywordForm.length; i++) {
        let keyword = "keyword" + i;
        this.keywordForm[i - 1].keyword.color = pro[keyword].color;
        this.keywordForm[i - 1].keyword.value = pro[keyword].value;
      }
      this.proDataMsgFrom.msgTxtColor = pro["keyword" + splitKey[1]].color;
      this.proDataMsgFrom.msgTxt = pro["keyword" + splitKey[1]].value;
      // 显示数据类型
      for (let i = 0; i < this.proDataPointList.length; i++) {
        if (this.proDataPointList[i].enName === split[0]) {
          if (this.proDataPointList[i].dataType === "枚举") {
            this.currentPoint.enumValue = this.proDataPointList[i].enumValue;
            this.proDataMsgFrom.enumContent = split[2];
          }
          if (this.proDataPointList[i].dataType === "数值") {
            if (">" === split[1]) {
              this.numOptionsValue = "1";
            }
            if ("=" === split[1]) {
              this.numOptionsValue = "2";
            }
            if ("<" === split[1]) {
              this.numOptionsValue = "3";
            }
            this.proDataMsgFrom.numValue = split[2];
          }
          if (this.proDataPointList[i].dataType === "布尔值") {
            this.booleanOptionsValue = split[2];
          }
          // this.currentPoint.dataType = this.proDataPointList[i].dataType;
          this.currentPoint = this.proDataPointList[i];
          this.proDataMsgFrom.name = this.proDataPointList[i].name;
          this.proDataMsgFrom.enName = this.proDataPointList[i].enName;
          this.proDataMsgFrom.dataType = this.proDataPointList[i].dataType;
        }
      }

      proDataMsgFrom.msgMark = pro.mark.value;
      proDataMsgFrom.msgMarkColor = pro.mark.color;
      proDataMsgFrom.msgFirst = pro.first.value;
      proDataMsgFrom.msgFirstColor = pro.first.color;
    },

    // 删除模板消息
    delTempIdMsg(pro) {
      this.$confirm("是否删除该模板消息？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios.delete(this.url + "/proTempIdMsg/delProTempIdMsg", {
          params: {
            id: pro.msgId.value,
            templateId: pro.templateId.value
          }
        }).then(res => {
          console.log("delProTempIdMsg res:", res);
          if (res.data.code !== 200) {
            this.$message.error(res.data.msg);
          } else {
            this.refreshTempIdMsg();
            this.$message({message: '删除成功', type: 'success'});
          }
        }).catch(err => {
          this.$notify({title: '错误', message: err, type: 'warning'})
          return false
        })
      })
    },

    // 获取当前应用的模板消息
    tempMsgSelectByTempId() {
      this.$axios.get(this.url + "/proTempIdMsg/selectProTempMsgByAppId" + "?miniAppId=" + this.miniAppId).then(res => {
        console.log("selectProTempMsgByAppId :", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.proTemplateMsgList = res.data.data;
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    // 添加模板id
    addTempIdMsg() {
      let proDataMsgFrom = this.proDataMsgFrom;
      proDataMsgFrom.keywordForm = this.keywordForm;
      proDataMsgFrom.productKey = this.proDetail.productKey;
      proDataMsgFrom.gomini = "0";
      if (this.checked) {
        proDataMsgFrom.gomini = "1";
      }
      if (this.numOptionsValue === "1") {
        proDataMsgFrom.numContent = "大于";
      }
      if (this.numOptionsValue === "2") {
        proDataMsgFrom.numContent = "等于";
      }
      if (this.numOptionsValue === "3") {
        proDataMsgFrom.numContent = "小于";
      }
      proDataMsgFrom.booValue = this.booleanOptionsValue;
      if (proDataMsgFrom.productKey === undefined || proDataMsgFrom.templateId === undefined
          || proDataMsgFrom.keywordFormValue === undefined || proDataMsgFrom.msgType === undefined
          || proDataMsgFrom.msgFirst === undefined || proDataMsgFrom.msgFirstColor === undefined || proDataMsgFrom.msgMark === undefined
          || proDataMsgFrom.msgMarkColor === undefined) {
        this.$message.error('输入不能为空');
        return;
      }
      console.log("proDataMsgFrom:", this.proDataMsgFrom)
      this.$axios.post(this.url + "/proTempIdMsg/addTempIdMsg", proDataMsgFrom).then(res => {
        console.log("addTempIdMsg res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.showAddTempMsg = false;
          this.$message({message: '添加成功', type: 'success'});
          this.refreshTempIdMsg();
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    // 获取id选择改变的事件 根据选择的id查询系统整个对象，
    selectChange(event) {
      console.log("selectChange event:", event);
      for (let i = 0; i < this.proDataPointList.length; i++) {
        if (this.proDataPointList[i].id === event) {
          this.currentPoint = this.proDataPointList[i];
          this.proDataMsgFrom.name = this.proDataPointList[i].name;
          this.proDataMsgFrom.enName = this.proDataPointList[i].enName;
          this.proDataMsgFrom.dataType = this.proDataPointList[i].dataType;
        }
      }
    },

    // 添加一行keyword 或者空行的值
    addLine(index) {
      const keywordCount = "keyword" + (index + 2);
      let jsonKey = {};
      jsonKey.key = keywordCount;
      jsonKey.keyword = {value: "", color: ""};
      this.keywordForm.push(jsonKey);
    },
    addLineNull() {
      let jsonKey = {};
      jsonKey.key = "keyword1";
      jsonKey.keyword = {"value": "", "color": ""};
      this.keywordForm.push(jsonKey);
    },

    // 刷新模板消息
    refreshTempIdMsg() {
      this.$axios.get(this.url + "/proTempIdMsg/selectALLProTempIdMsg" + "?productKey=" + this.proDetail.productKey).then(res => {
        console.log("refresh selectALLProTempIdMsg res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.proTemplateMsgList = res.data.data;
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    }

  },

  //自动触发写入的函数
  mounted: function () {
    this.proDetail = JSON.parse(sessionStorage.getItem("product"));

    // 获取当前产品的模板消息
    this.$axios.get(this.url + "/proTempIdMsg/selectALLProTempIdMsg" + "?productKey=" + this.proDetail.productKey).then(res => {
      console.log("selectALLProTempIdMsgt res:", res);
      if (res.data.code !== 200) {
        this.$message.error(res.data.msg);
      } else {
        this.proTemplateMsgList = res.data.data;
      }
    }).catch(err => {
      this.$notify({title: '错误', message: err, type: 'warning'})
      return false
    })

    // 产品配置信息
    this.$axios.get(this.url + "/proAppConfig/selectProAppConfigList" + "?productId=" + this.proDetail.productKey).then(res => {
      console.log("selectProAppConfigList res:", res);
      if (res.data.code !== 200) {
        this.$message.error(res.data.msg);
      } else {
        this.proAppConfigList = res.data.data;
      }
    }).catch(err => {
      this.$notify({title: '错误', message: err, type: 'warning'})
      return false
    })

    // 初始化数据点列表
    this.$axios.get(this.url + "/proDataPoint/selectProductPoint" + "?productKey=" + this.proDetail.productKey).then(res => {
      console.log("proDataPointAll res:", res);
      if (res.data.code !== 200) {
        this.$message.error(res.data.msg);
      } else {
        this.proDataPointList = res.data.data;
      }
    }).catch(err => {
      this.$notify({title: '错误', message: err, type: 'warning'})
      return false
    })
  }
}

</script>

<style>
.el-dialog--center {
  text-align: center;
  min-width: 900px;
}
</style>