<template>
  <div>
  </div>
</template>


<script>
// 本地调试
// const url = "http://localhost:8065";
// 内网穿透
// const url = "https://58cf-36-22-63-26.jp.ngrok.io";
// 线上
const url = "http://115.159.158.183:8065";



export default {
  url
}
</script>