<template>
  <div class="">
    <div class="divHeader">
      <div style="font-weight:600"> 数据点</div>
      <div style="display: flex;flex-direction: row">
        <div>
          <el-button icon="el-icon-plus" @click="isShowAddPoint = true;dataPoint = {}">添加</el-button>
        </div>
        <div style="margin: 0 10px;">
          <el-button icon="el-icon-upload2" @click="exportExcel">导出</el-button>
          <!--          <el-select v-model="excelValue" @change="handleExcel" placeholder="操作" style="width: 130px">-->
          <!--            <el-option v-for="item in excelOptions" :key="item.value" :label="item.label" :value="item.value">-->
          <!--            </el-option>-->
          <!--          </el-select>-->
        </div>
        <div>
          <el-upload
              class="upload-demo"
              action="http://localhost:8065/proDataPoint/importProductPoint"
              multiple
              accept=".xlsx, .xls"
              :data={proDetail:JSON.stringify(proDetail)}
              :limit="1"
              :on-exceed="handleExceed"
              :on-success="uploadOk"
              :show-file-list="false"
          >
            <el-button icon="el-icon-download">导入</el-button>
          </el-upload>
        </div>
      </div>
    </div>
    <div style="height: 1px;background-color:   rgb(215,215,215)"></div>

    <div v-if="proDataPointList.length === 0" style="height: 150px;line-height: 150px;font-size: 16px">空</div>
    <!--    数据点显示 列表-->
    <div v-for="(pro,index) in proDataPointList" :key="index" class="pdp_frodatapoint">
      <div class="pdp_pointname">
        <div class="pdp_onecown">
          <div> 名称：{{ pro.name }}</div>
          <div> 标识名：{{ pro.enName }}</div>
          <div> 读写类型：{{ pro.readWriteType }}</div>
          <div> 数据类型：{{ pro.dataType }}</div>
        </div>
        <div v-if="pro.dataType === '枚举' || pro.dataType === '数值'" class="pdp_pointtwo">
          <div v-if="pro.dataType === '枚举'">
            枚举值：{{ pro.enumValue }}
          </div>
          <div v-if="pro.dataType === '数值'" style="display: flex;flex-direction: row;justify-content: space-between;">
            <div>数值范围：{{ pro.minValue }} - {{ pro.maxValue }}</div>
            <div style="margin-left: 30px">步长：{{ pro.increase }}</div>
          </div>
        </div>
      </div>
      <!-- 修改与删除-->
      <div class="pdp_model">
        <el-button class="el-icon-edit" @click="updatePoint(pro)"
                   style="margin-left: 5px;border: 1px solid #ffffff;font-size: 18px"></el-button>
        <el-button class="el-icon-delete" @click="delPoint(pro)"
                   style="margin-right: 5px;border: 1px solid #ffffff;font-size: 18px"></el-button>
      </div>
    </div>

    <!-- 添加数据点-->
    <el-dialog title="添加数据点" :visible.sync="isShowAddPoint" width="40%" center style="line-height: 120px">
      <el-form ref="form" :model="dataPoint" style="text-align: center;margin-top: -30px">
        <div class="pdp_hight">&nbsp;&nbsp;中文名：
          <el-input v-model="dataPoint.name" style="width: 35%"></el-input>
        </div>
        <div class="pdp_hight">&nbsp;&nbsp;标识名：
          <el-input v-model="dataPoint.enName" style="width: 35%"></el-input>
        </div>
        <div class="pdp_hight">读写类型：
          <el-select v-model="writeOrReadValue" placeholder="操作" style="width: 35%">
            <el-option v-for="item in writeOrReadOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="pdp_hight">数据类型：
          <el-select v-model="dateTypeValue" placeholder="操作" style="width: 35%">
            <el-option v-for="item in dateTypeOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div v-if="dateTypeValue === '2'" class="pdp_hight">&nbsp;&nbsp;枚举值：
          <el-input v-model="dataPoint.enumValue" style="width: 35%"></el-input>
        </div>
        <div v-if="dateTypeValue === '3'">
          <div class="pdp_hight">&nbsp;&nbsp;最小值：
            <el-input v-model="dataPoint.minValue" style="width: 35%"></el-input>
          </div>
          <div class="pdp_hight">&nbsp;&nbsp; 最大值：
            <el-input v-model="dataPoint.maxValue" style="width: 35%"></el-input>
          </div>
          <div class="pdp_hight">&nbsp;&nbsp;分辨率：
            <el-input v-model="dataPoint.increase" style="width: 35%"></el-input>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowAddPoint = false" style="width: 120px">取消</el-button>
        <el-button type="primary" @click="createProDataPoint()" style="width: 120px">创建</el-button>
      </span>
    </el-dialog>

    <!--    修改数据点-->
    <el-dialog title="修改数据点" :visible.sync="isShowUpdatePoint" width="40%" center
               style="line-height: 120px;min-width: 800px">
      <el-form ref="form" :model="dataPoint" style="text-align: center;margin-top: -30px">
        <div class="pdp_hight">
          &nbsp;&nbsp;名称：
          <el-input v-model="dataPoint.name" style="width: 35%"></el-input>
        </div>
        <div class="pdp_hight">
          &nbsp;&nbsp;标识名：
          <el-input v-model="dataPoint.enName" style="width: 35%"></el-input>
        </div>
        <div class="pdp_hight">
          读写类型：
          <el-select v-model="writeOrReadValue" placeholder="操作" style="width: 35%">
            <el-option v-for="item in writeOrReadOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="pdp_hight">
          数据类型：
          <el-select v-model="dateTypeValue" placeholder="操作" style="width: 35%">
            <el-option v-for="item in dateTypeOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div v-if="dateTypeValue === '2'" class="pdp_hight">
          &nbsp;&nbsp;枚举值：
          <el-input v-model="dataPoint.enumValue" style="width: 35%"></el-input>
        </div>
        <div v-if="dateTypeValue === '3'">
          <div class="pdp_hight">
            &nbsp;&nbsp;最小值：
            <el-input v-model="dataPoint.minValue" style="width: 35%"></el-input>
          </div>
          <div class="pdp_hight">
            &nbsp;&nbsp; 最大值：
            <el-input v-model="dataPoint.maxValue" style="width: 35%"></el-input>
          </div>
          <div class="pdp_hight">
            &nbsp;&nbsp;分辨率：
            <el-input v-model="dataPoint.increase" style="width: 35%"></el-input>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowUpdatePoint = false" style="width: 120px">取消</el-button>
        <el-button type="primary" @click="updatePointOk()" style="width: 120px">确认</el-button>
      </span>
    </el-dialog>

  </div>
</template>


<script>
import axios from "axios";
import fileDownload from 'js-file-download'


export default {
  name: 'prodatapoint',

  data() {
    return {
      proDetail: null,
      proDataPointList: [],
      excelOptions: [{
        value: '1',
        label: '导入Excel'
      }, {
        value: '2',
        label: '导出Excel'
      }],
      excelValue: '',
      isShowAddPoint: false,
      isShowUpdatePoint: false,
      dataPoint: {
        productKey: "",
        name: "",
        enumValue: "",
        enName: "",
        dataType: "",
        minValue: "",
        maxValue: "",
        increase: "",
        readWriteType: "",
      },
      writeOrReadOptions: [{
        value: '1',
        label: '只读'
      }, {
        value: '2',
        label: '可写'
      }],
      writeOrReadValue: "1",

      dateTypeOptions: [{
        value: '1',
        label: '布尔值'
      }, {
        value: '2',
        label: '枚举'
      }, {
        value: '3',
        label: '数值'
      }],
      dateTypeValue: "1",
      updatePointId: "",
      fileName: "数据点.xlsx",
    }
  },
  methods: {
    uploadOk() {
      this.refreshProduct();
      this.$message({
        message: '导入成功',
        type: 'success'
      });
    },
    // 导出文件
    handleExceed(files) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`);
    },
    exportExcel() {
      axios.get(this.url + "/proDataPoint/exportProductPoint?productKey=" + this.proDetail.productKey, {
        responseType: 'blob' //返回的数据类型
      }).then(res => {
        console.log("res:", res);
        if (res.status === 200) {
          this.$message({
            message: '导出成功',
            type: 'success'
          });
        }
        fileDownload(res.data, this.fileName);
      })
    },

    // 创建数据点
    createProDataPoint() {
      this.dataPoint.productKey = this.proDetail.productKey;
      this.dataPoint.readWriteType = this.writeOrReadOptions[this.writeOrReadValue - 1].label;
      this.dataPoint.dataType = this.dateTypeOptions[this.dateTypeValue - 1].label;
      // console.log("createProDataPoint this.dataPoint: " + this.dataPoint)
      this.$axios.post(this.url + "/proDataPoint/addProductPoint", this.dataPoint).then(res => {
        console.log("addProductPoint res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.isShowAddPoint = false;
          this.$message({
            message: '添加成功',
            type: 'success'
          });
          this.refreshProduct();
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    // 修改数据点
    updatePoint(pro) {
      this.isShowUpdatePoint = true;
      this.updatePointId = pro.id;
      this.dataPoint = pro;
    },

    // 刷新列表
    refreshProduct() {
      // 直接刷新
      this.$axios.get(this.url + "/proDataPoint/selectProductPoint" + "?productKey=" + this.proDetail.productKey).then(res => {
        console.log("refreshProductPoint res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.proDataPointList = res.data.data;
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    updatePointOk() {
      this.dataPoint.id = this.updatePointId;
      this.dataPoint.productKey = this.proDetail.productKey;
      this.dataPoint.readWriteType = this.writeOrReadOptions[this.writeOrReadValue - 1].label;
      this.dataPoint.dataType = this.dateTypeOptions[this.dateTypeValue - 1].label;
      this.$axios.put(this.url + "/proDataPoint/updateProductPoint", this.dataPoint).then(res => {
        console.log("updateProductPoint res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.isShowUpdatePoint = false;
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.refreshProduct();
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    // 删除
    delPoint(pro) {
      this.$confirm("是否删除数据点？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios.delete(this.url + "/proDataPoint/delProductPoint", {
          params: {id: pro.id}
        }).then(res => {
          console.log("delProductPoint res:", res);
          if (res.data.code !== 200) {
            this.$message.error(res.data.msg);
          } else {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.refreshProduct();
          }
        }).catch(err => {
          this.$notify({title: '错误', message: err, type: 'warning'})
          return false
        })
      }).catch(() => {
      });
    }
  },

  mounted: function () {
    this.proDetail = JSON.parse(sessionStorage.getItem("product"));
    // 初始化数据点列表
    this.$axios.get(this.url + "/proDataPoint/selectProductPoint" + "?productKey=" + this.proDetail.productKey).then(res => {
      console.log("selectProductPointAll res:", res);
      if (res.data.code !== 200) {
        this.$message.error(res.data.msg);
      } else {
        this.proDataPointList = res.data.data;
      }
    }).catch(err => {
      this.$notify({title: '错误', message: err, type: 'warning'})
      return false
    })

  }
}

</script>

<style>
.pdp_model {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 15%;
  background-color: #ffffff;
  margin: 0 0;
}


.pdp_hight {
  height: 60px;
  line-height: 60px;
}

.pdp_pointtwo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  line-height: 30px;
}

.pdp_frodatapoint {
  font-size: 14px;
  border: 1px solid rgb(215, 215, 215);
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  min-width: 800px
}

.pdp_pointname {
  width: 80%;
  background-color: #ffffff;
  margin: 10px
}

.pdp_onecown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 30px;
}

.divHeader {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -10px;
  font-weight: 600;
}

</style>