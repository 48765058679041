<template>
  <div>
    <div class="divHeaderS" style="font-weight: 600">
      <span v-if="!isShowDeviceDetail">设备日志</span>
      <span @click="backDevicePage()" v-if="isShowDeviceDetail"><span style="color:#8c939d;">设备日志 /  </span>日志详情</span>
    </div>
    <div style="height: 1px;background-color:  rgb(215,215,215)"></div>

    <!-- 搜索区域-->
    <div v-if="!isShowDeviceDetail">
      <el-input placeholder="请输入Mac" v-model="inputMac" class="input-with-select"
                style="height: 40px;width: 300px;float: right">
        <el-button slot="append" icon="el-icon-search" @click="selectDeviceByMac()"></el-button>
      </el-input>
      <!--    设备列表，当前产品的设备-->
      <div style="margin-top: 20px">
        <div>
          <el-table :data="deviceList" stripe height="600" style="width: 100%;line-height: 60px">
            <el-table-column prop="mac" label="设备MAC" min-width="23%"></el-table-column>
            <el-table-column prop="onlineTime" label="首次上线" min-width="23%"
                             :formatter='onlineTimeFormat'></el-table-column>
            <el-table-column prop="outlineTime" label="最后上线" min-width="23%"
                             :formatter='outlineTimeFormat'></el-table-column>
            <el-table-column prop="onlineCount" label="上线次数" min-width="11%"></el-table-column>
            <el-table-column prop="isOnline" label="状态" :formatter='isOnlineFormat' min-width="10%"></el-table-column>
            <el-table-column fixed="right" label="操作" min-width="10%">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="deviceLog(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!--    设备详情日志-->
    <div v-if="isShowDeviceDetail">
      <div style="line-height: 50px; float: left;margin-top: 20px">Mac: <span style="padding-left: 20px"> {{
          deviceMac
        }}</span></div>
      <div>
        <el-table :data="deviceLogList" stripe height="600" style="width: 100%;line-height: 60px">
          <el-table-column prop="ipAddress" label="IP" min-width="13%"></el-table-column>
          <el-table-column prop="cTime" label="时间" min-width="18%"
                           :formatter='deviceLogFormat'></el-table-column>
          <el-table-column prop="onlineTime" label="时长 s" min-width="9%"></el-table-column>
          <el-table-column prop="msgContent" label="事件" min-width="30%"></el-table-column>
          <el-table-column prop="type" label="类型" min-width="14%"></el-table-column>
          <el-table-column prop="reason" label="原因" min-width="16%"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'devicelog',

  data() {
    return {
      isShowDeviceDetail: false,
      inputMac: "",
      proDetail: null, // 产品信息
      deviceList: [],
      deviceLogList: [],
      deviceMac: "",
    }
  },
  methods: {
    // 格式化显示时间
    deviceLogFormat(row) {
      return row.cTime.substring(0, 10) + " " + row.cTime.substring(11, 19)
    },

    backDevicePage() {
      this.isShowDeviceDetail = false;
    },

    // 查看详情，同时实时刷新日志
    deviceLog(row) {
      this.isShowDeviceDetail = true;
      this.deviceMac = row.mac;
      this.$axios.get(this.url + "/deviceLog/deviceLog" + "?mac=" + row.mac).then(res => {
        console.log("deviceLog res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.deviceLogList = res.data.data;
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    // 查找设备
    selectDeviceByMac() {
      this.$axios.get(this.url + "/device/deviceMac" + "?mac=" + this.inputMac).then(res => {
        console.log("deviceMac res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.deviceList = res.data.data;
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    // 格式化表格
    onlineTimeFormat(row) {
      if (null == row.onlineTime) {
        return "/";
      }
      return row.onlineTime.substring(0, 10) + " " + row.onlineTime.substring(11, 19)
    },
    outlineTimeFormat(row) {
      return row.onlineTime.substring(0, 10) + " " + row.onlineTime.substring(11, 19)
    },
    isOnlineFormat(row) {
      if (row.isOnline === 0) {
        return "离线";
      } else {
        return "在线";
      }
    }

  },

  // 页面初始化执行
  mounted: function () {
    this.proDetail = JSON.parse(sessionStorage.getItem("product"));
    this.$axios.get(this.url + "/device/deviceList" + "?productKey=" + this.proDetail.productKey).then(res => {
      console.log("deviceLogList res:", res);
      if (res.data.code !== 200) {
        this.$message.error(res.data.msg);
      } else {
        this.deviceList = res.data.data;
      }
    }).catch(err => {
      this.$notify({title: '错误', message: err, type: 'warning'})
      return false
    })
  }
}

</script>

<style>

</style>