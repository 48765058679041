<template>
  <slide-verify
      :l="42"
      :r="10"
      :w="400"
      :h="250"
      :imgs="picArray"
      :show="false"
      slider-text="向右滑动完成验证"
      ref="slideverify"
      @success="onSuccess"
      @fail="onFail"
      @refresh="onRefresh"
  ></slide-verify>
</template>

<script>
export default {

  name: "SliderVerify",
  data() {

    return {

      //在data中引入`assets`中的图片可以通过`require`的方式来引入
      picArray: [
        require("../../img/veri1.jpg"),
        require("../../img/veri2.jpg"),
        require("../../img/veri3.jpg"),
      ],
    };
  },
  methods: {
    onSuccess() {
      //往父级传递验证通过的函数
      this.$emit("success");
      console.log("succ")
    },
    onReset() {
      //重置图片验证组件
      this.$refs.slideverify.reset();
      console.log("reset")
    },
    onFail() {
      console.log("fail")
    },
    onRefresh() {
    },
  },
};
</script>
