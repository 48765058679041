<template>
  <div style="background-color: #F5F5F5;width: 100%">
    <div style="background-color: rgb(179,192,209);height: 60px;min-width: 1000px;">
      <div style=" margin-left: 20px;display: flex;flex-direction: row;">
        <img src="../../img/yh_logo.png"
             style="width: 30px;height: 30px;padding-right: 10px;margin-top: 15px;margin-left: 120px" alt="">
        <div class="pal_logo" style="line-height: 60px;">YH 云平台</div>
      </div>
    </div>

    <div style="height: 30px;background-color: #ffffff"></div>
    <div class="pl_head" style="mini-width: 1000px;">
      <div style="margin-left: 30px;font-size: 18px;font-weight: 600">
        友好智能
      </div>
      <div style="margin-right: 30px;display: flex;flex-direction: row;">
        <el-input placeholder="请输入ProductKey" v-model="inProductKey" class="input-with-select"
                  style="height: 40px;margin-top: 5px;width: 330px">
          <el-button slot="append" icon="el-icon-search" @click="selectProduct()"></el-button>
        </el-input>
        <el-button icon="el-icon-plus" type="primary" style="height: 40px;margin-top: 5px;margin-left: 10px;"
                   @click="isCreateProduct = true">
          创键产品
        </el-button>
      </div>
    </div>
    <div style="margin: 2px;height: 1px;background-color:   rgb(215,215,215);min-width: 1000px;"></div>

    <div style="background-color: #F5F5F5;margin: 0 20%;text-align: center;width: 60%;">
      <div style="min-width: 1000px;padding: 0; height: 100%;">
        <div style="display: flex;flex-direction: column">
          <div class="productOnline"
               style="line-height: 60px;height: 60px;text-align: left;font-size: 18px;padding-left: 30px;color: #05b0ed">
            已发布产品
          </div>
          <div v-if="productAllListProd.length === 0 " style="font-size: 16px;min-height: 320px"> 空</div>
          <div>
            <div v-for="(pro,index) in productAllListProd" :key="index" @click="goProductDetail(pro)">
              <el-col :span="8" style="border-radius: 6px;min-width: 320px;padding: 10px">
                <div class="grid-content bg-purple;"
                     style="padding: 10px;border:1px solid  rgb(215,215,215);background-color: #FFFFFF">
                  <div style="height: 80px;line-height: 80px;font-size: 16px">
                    {{ pro.name }}
                  </div>
                  <div style="height: 40px;line-height: 40px;font-size: 10px;">
                    productKey: {{ pro.productKey }}
                  </div>
                  <div style="height: 40px;line-height: 40px;font-size: 10px;">
                    创键时间: {{ pro.cTime.substring(0, 10) + " " + pro.cTime.substring(11, 19) }}
                  </div>
                  <div style="height: 40px;line-height: 40px;font-size: 10px;padding-bottom: 30px">
                    修改时间: {{ pro.mTime.substring(0, 10) + " " + pro.mTime.substring(11, 19) }}
                  </div>
                </div>
              </el-col>
            </div>
          </div>
          <div class="productOnline">
            开发中产品
          </div>
          <div v-if="productAllListDev.length === 0"
               style="font-size: 16px;line-height: 60px;float: left;min-height: 320px">
            空
          </div>
          <!--v-for开始下标是1,包含最大值,是第一个对象，index是所有对象-->
          <div>
            <div v-for="(pro,index) in productAllListDev" :key="index" @click="goProductDetail(pro)">
              <el-col :span="8" style="border-radius: 6px;min-width: 320px;padding: 10px">
                <div class="grid-content bg-purple;"
                     style="padding: 10px;border:1px solid   rgb(215,215,215);background-color: #FFFFFF">
                  <div style="height: 80px;line-height: 80px;font-size: 16px">
                    {{ pro.name }}
                    <span v-if="pro.status === 1" style=" color: green">
                  （审核中）
                </span>
                  </div>
                  <div style="height: 40px;line-height: 40px;font-size: 10px;">
                    productKey:   {{ pro.productKey }}
                  </div>

                  <div style="height: 40px;line-height: 40px;font-size: 10px;">
                    创键时间: {{ pro.cTime.substring(0, 10) + " " + pro.cTime.substring(11, 19) }}
                  </div>
                  <div style="height: 40px;line-height: 40px;font-size: 10px;padding-bottom: 30px">
                    修改时间: {{ pro.mTime.substring(0, 10) + " " + pro.mTime.substring(11, 19) }}
                  </div>
                </div>
              </el-col>
            </div>
          </div>
        </div>
        <el-dialog title="创键产品" :visible.sync="isCreateProduct" width="40%" center>
          <el-form ref="form" :model="product" style="text-align: center">
            <div style="height: 60px;line-height: 60px;">
              产品名称：
              <el-input v-model="product.name" style="width: 30%"></el-input>
            </div>
            <div style="height: 60px;line-height: 60px;">
              产品类型：
              <el-input v-model="product.type" style="width: 30%"></el-input>
            </div>
            <div style="height: 60px;line-height: 60px;">
              产品厂商：
              <el-input v-model="product.manu" style="width: 30%"></el-input>
            </div>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button @click="isCreateProduct = false" style="width: 120px">取消</el-button>
              <el-button type="primary" @click="createProduct" style="width: 120px">创建</el-button>
          </span>
        </el-dialog>
      </div>
    </div>

    <el-footer
        style="height: 80px;background-color: #F5F5F5;line-height: 80px;margin-top: 20px;font-size: 13px;text-align: center">
      首页 | 隐私声明 | 用户协议 | 关于我们
    </el-footer>

  </div>
</template>

<script>


export default {
  name: 'ProList',

  data() {
    return {
      inProductKey: "", // 输入搜素
      isCreateProduct: false,
      productAllListDev: [],
      productAllListProd: [],
      product: {
        name: '',
        type: '',
        manu: ''
      }
    }
  },
  methods: {
    // 产品详情
    goProductDetail: function (product) {
      console.log("click product goProductDetail:", product);
      sessionStorage.setItem("product", JSON.stringify(product));
      this.$router.push({
        path: '/productDetail',
      })
    },

    // 查找产品
    selectProduct() {
      this.$axios.get(this.url + "/product/selectProduct?productKey=" + this.inProductKey).then(res => {
        console.log("selectProduct res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.productAllListProd = [];
          this.productAllListDev = [];
          if (res.data.data.status === 2) {
            this.productAllListProd.push(res.data.data);
          } else {
            this.productAllListDev.push(res.data.data);
          }
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    // 创键产品
    createProduct() {
      this.$axios.post(this.url + "/product/registerProduct", this.product).then(res => {
        console.log("createProduct res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          // 创键成功隐藏页面并刷新列表
          this.productAllListProd = [];
          this.productAllListDev = [];
          this.isCreateProduct = false;
          this.$axios.get(this.url + "/product/selectProductList").then(res => {
            console.log("selectProductList res:", res);
            if (res.data.code !== 200) {
              this.$message.error(res.data.msg);
            } else {
              for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].status === 2) {
                  this.productAllListProd.push(res.data.data[i]);
                } else {
                  this.productAllListDev.push(res.data.data[i]);
                }
              }
            }
          }).catch(err => {
            this.$notify({title: '错误', message: err, type: 'warning'})
            return false
          })
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },
  },

  mounted: function () {
    // 初始化产品列表
    this.$axios.get(this.url + "/product/selectProductList").then(res => {
      console.log("selectProductList res:", res);
      if (res.data.code !== 200) {
        this.$message.error(res.data.msg);
      } else {
        for (let i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].status === 2) {
            this.productAllListProd.push(res.data.data[i]);
          } else {
            this.productAllListDev.push(res.data.data[i]);
          }
        }
      }
    }).catch(err => {
      this.$notify({title: '错误', message: err, type: 'warning'})
      return false
    })
  }
}

</script>

<style>
.productOnline {
  min-width: 100px;
  line-height: 60px;
  height: 60px;
  background-color: #F5F5F5;
  text-align: left;
  font-size: 18px;
  margin-top: 30px;
  padding-left: 30px
}

.pl_head {
  background-color: #ffffff;
  height: 50px;
  line-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20%;
  min-width: 1000px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>