<template>
  <div class="index">
    待开发...
  </div>
</template>


<script>
export default {
  name: 'mcudev',

  data() {
    return {

    }
  },
  methods: {

  },

  //自动触发写入的函数
  mounted: function () {

  }
}

</script>

<style>

</style>