<template>
  <div class="background">
    <el-form :model="user" :rules="rules" ref="ruleForm" label-width="80px" class="login-ruleForm">
      <div class="vHead">
        <img src="../../img/yh_logo.png" class="logoImg">
        <div class="headWorlds"> YH 物联平台</div>
      </div>
      <el-form-item label="用户名：" prop="username" class="sl_user">
        <el-input v-model="user.username"></el-input>
      </el-form-item>
      <el-form-item label="密码：" prop="psw">
        <label slot="label">密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:</label>
        <el-input v-model="user.password" type="password"></el-input>
      </el-form-item>
      <div class="recoverPsw" @click="forgetPsw()">
        <!--        忘记密码 >-->
      </div>
      <div class="sl_loginButton">
        <el-button type="primary" size="medium" @click="sysLogin('user')" style="width: 120px">登录</el-button>
      </div>
    </el-form>

    <el-form style="margin-top: -80px;text-align: center">
      <div>
        <div style=""> 宁波友好智能安防科技有限公司 <a href="https://beian.miit.gov.cn/">
          浙ICP备17054656号-2</a></div>
        <div> 传真：0574-86811112 E-MAIL：wangchao@uh-home.com</div>
        <div> 宁波北仑莫干山路36号智能装备园，B#2-9 Copyright © 2024宁波友好智能安防科技有限公司. All Rights
          Reserved.
        </div>
      </div>
    </el-form>

  </div>
</template>

<script>
export default {
  name: "SysLogin",

  data() {
    return {
      user: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
        ],
      },
    };
  },
  methods: {
    // 重置登录密码
    forgetPsw() {
      this.$router.replace("/recoverpsw");
    },

    // 登录
    sysLogin() {
      this.$axios.post(this.url + "/sys/adminLogin", this.user).then(res => {
        console.log("sysLogin res:", res);
        if (res.data.code !== 200) {//登录失败
          this.$message.error(res.data.msg);
        } else {
          this.$message({
            message: '登录成功',
            type: 'success'
          });
          this.$router.replace("/ProductAllList");
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },
  }
}
</script>


<style>
.login-ruleForm {
  text-align: center;
  border-radius: 15px;
  background-clip: padding-box;
  margin: 220px auto;
  width: 280px;
  padding: 15px 35px 15px 35px;
  background: #f7f7f7;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px rgb(25, 202, 233);
  opacity: 1;
}

.background {
  width: 100%;
  height: 100vh;
  background: url("../../img/back.jpg") center center no-repeat;
  background-size: 100% 100%;
  position: fixed;
}

.vHead {
  margin-left: 34px;
  margin-top: 20px;
  display: flex;
  flex-direction: row
}

.logoImg {
  width: 36px;
  height: 36px;
  margin-left: 15px;
  margin-top: 15px;
}

.headWorlds {
  font-size: 22px;
  line-height: 60px;
  margin-left: 10px;
}

.sl_loginButton {
  margin: 80px 0 40px 0
}

.sl_user {
  margin-top: 60px
}

.recoverPsw {
  font-size: 10px;
  color: #8c939d;
  text-align: right;
  width: 70px;
  float: right
}
</style>
