<template>
  <div class="">
    <div class="divHeader">
      <div>应用配置</div>
      <div>
        <el-button icon="el-icon-plus" @click="isShowAddApp = true;proAppConfig= {}">添加</el-button>
      </div>
    </div>
    <div style="height: 1px;background-color:   rgb(215,215,215)"></div>

    <div v-if="proAppConfigList.length === 0" style="height: 150px;line-height: 150px;font-size: 16px">空</div>
    <div style="height: 10px"></div>
    <div v-for="(pro,index) in proAppConfigList" :key="index" style="">
      <div style="border-radius: 6px;min-width: 300px;padding: 10px;font-size: 14px;">
        <div class="grid-content bg-purple;"
             style="padding: 10px 20px;border:1px solid   rgb(215,215,215);text-align: left;display: flex;flex-direction: row">
          <div style="width: 80%">
            <div style="line-height: 40px;">
              <span style="font-weight: 600">应用名称:</span> {{ pro.plateform }}
            </div>
            <div style="line-height: 40px;">
              <span style="font-weight: 600">设备名称:</span> {{ pro.appName }}
            </div>
            <div style="line-height: 40px;">
              <span style="font-weight: 600"> APP_ID: </span>
              {{ pro.miniAppId }}
            </div>
            <div style="line-height: 40px;">
              <span style="font-weight: 600"> APP_SECRET: </span>
              {{ pro.miniAppSecret }}
            </div>
            <div style="line-height: 40px;"><span style="font-weight: 600">  TEMPLATE_ID:  </span>
              {{ pro.templateId }}
            </div>
            <div style="line-height: 40px;"><span style="font-weight: 600"> TOKEN_URL: </span>
              {{ pro.tokenUrl }}
            </div>
          </div>
          <div style="background-color: #ffffff;line-height: 40px;">
            <div style="font-weight: 600"> 操作:</div>
            <div @click="isShowModiApp  = true;modiProAppConfigId = pro.id;proAppConfig=pro" style="color: #05b0ed">
              <el-button type="text">修改配置</el-button>
            </div>
            <div @click="delProAppConfig(pro)" style="color: #05b0ed">
              <el-button type="text">删除应用</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    创键应用-->
    <el-dialog title="创键应用" :visible.sync="isShowAddApp" width="40%" center style="line-height: 120px">
      <el-form style="line-height: 60px;text-align: center;margin-top: -30px">
        <div style="display: flex;flex-direction: row;justify-content: center">
          <div style="text-align: right;margin-right: 10px;width: 100px;background-color: #ffffff">
            应用名称:
          </div>
          <el-input v-model="proAppConfig.plateform" style="width: 50%;"></el-input>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: center">
          <div style="text-align: right;margin-right: 10px;width: 100px;background-color: #ffffff">
            设备名称:
          </div>
          <el-input v-model="proAppConfig.appName" style="width: 50%;"></el-input>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: center">
          <div style="text-align: right;margin-right: 10px;width: 100px;background-color: #ffffff">
            APP_ID:
          </div>
          <el-input v-model="proAppConfig.miniAppId" style="width: 50%;"></el-input>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: center">
          <div style="text-align: right;margin-right: 10px;width: 100px;background-color: #ffffff">
            APP_SECRET:
          </div>
          <el-input v-model="proAppConfig.miniAppSecret" style="width: 50%;"></el-input>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: center">
          <div style="text-align: right;margin-right: 10px;width: 100px;background-color: #ffffff">
            TEMPLATE_ID:
          </div>
          <el-input v-model="proAppConfig.templateId" style="width: 50%;"></el-input>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: center">
          <div style="text-align: right;margin-right: 10px;width: 100px;">
            TOKEN_URL:
          </div>
          <el-input v-model="proAppConfig.tokenUrl" style="width: 50%;"></el-input>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer" style="margin-top: -50px">
        <el-button @click="isShowAddApp = false" style="width: 120px">取消</el-button>
        <el-button type="primary" @click="addProAppConfig()" style="width: 120px">创建</el-button>
      </span>
    </el-dialog>

    <!--    修改应用-->
    <el-dialog title="修改应用配置" :visible.sync="isShowModiApp" width="40%" center
               style="line-height: 120px;min-width: 800px">
      <el-form style="line-height: 60px;margin-top: -30px">
        <div style="display: flex;flex-direction: row;justify-content: center">
          <div style="text-align: right;margin-right: 10px;width: 100px;background-color: #ffffff">
            应用名称:
          </div>
          <el-input v-model="proAppConfig.plateform" style="width: 50%;"></el-input>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: center">
          <div style="text-align: right;margin-right: 10px;width: 100px;background-color: #ffffff">
            设备名称:
          </div>
          <el-input v-model="proAppConfig.appName" style="width: 50%;"></el-input>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: center">
          <div style="text-align: right;margin-right: 10px;width: 100px;background-color: #ffffff">
            APP_ID:
          </div>
          <el-input v-model="proAppConfig.miniAppId" style="width: 50%;"></el-input>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: center">
          <div style="text-align: right;margin-right: 10px;width: 100px;background-color: #ffffff">
            APP_SECRET:
          </div>
          <el-input v-model="proAppConfig.miniAppSecret" style="width: 50%;"></el-input>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: center">
          <div style="text-align: right;margin-right: 10px;width: 100px;background-color: #ffffff">
            TEMPLATE_ID:
          </div>
          <el-input v-model="proAppConfig.templateId" style="width: 50%;"></el-input>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: center">
          <div style="text-align: right;margin-right: 10px;width: 100px;">
            TOKEN_URL:
          </div>
          <el-input v-model="proAppConfig.tokenUrl" style="width: 50%;"></el-input>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowModiApp = false" style="width: 120px">取消</el-button>
        <el-button type="primary" @click="modiProAppConfig()" style="width: 120px">修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
export default {
  name: 'appconfig',

  data() {
    return {
      proDetail: null,
      proAppConfigList: [],
      isShowAddApp: false,
      isShowModiApp: false,
      proAppConfig: {
        id: "",
        appName: "",
        productId: "",
        plateform: "",
        miniAppId: "",
        miniAppSecret: "",
        templateId: "",
        tokenUrl: ""
      },
      modiProAppConfigId: "", // 点击id
    }
  },
  methods: {
    // 添加产品应用
    addProAppConfig() {
      this.proAppConfig.productId = this.proDetail.productKey;
      this.$axios.post(this.url + "/proAppConfig/addProAppConfig", this.proAppConfig).then(res => {
        console.log("addProAppConfig res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.isShowAddApp = false;
          this.refreshProAppConfig();
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    // 修改产品应用
    modiProAppConfig() {
      this.proAppConfig.productId = this.proDetail.productKey;
      this.proAppConfig.id = this.modiProAppConfigId;
      this.$axios.put(this.url + "/proAppConfig/updateProAppConfig", this.proAppConfig).then(res => {
        console.log("updateProAppConfig res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.isShowModiApp = false;
          this.refreshProAppConfig();
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    // 删除产品应用
    delProAppConfig(pro) {
      this.$confirm("是否删除？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios.delete(this.url + "/proAppConfig/delProAppConfig", {
          params: {id: pro.id}
        }).then(res => {
          console.log("delProAppConfig res:", res);
          if (res.data.code !== 200) {
            this.$message.error(res.data.msg);
          } else {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.refreshProAppConfig();
          }
        }).catch(err => {
          this.$notify({title: '错误', message: err, type: 'warning'})
          return false
        })
      });
    },

    // 刷新产品配置
    refreshProAppConfig() {
      this.$axios.get(this.url + "/proAppConfig/selectProAppConfigList" + "?productId=" + this.proDetail.productKey).then(res => {
        console.log("refreshProAppConfig res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.proAppConfigList = res.data.data;
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    }
  },

  //自动触发写入的函数
  mounted: function () {
    this.proDetail = JSON.parse(sessionStorage.getItem("product"));
    // 初始化产品配置
    this.$axios.get(this.url + "/proAppConfig/selectProAppConfigList" + "?productId=" + this.proDetail.productKey).then(res => {
      console.log("selectProAppConfigList res:", res);
      if (res.data.code !== 200) {
        this.$message.error(res.data.msg);
      } else {
        this.proAppConfigList = res.data.data;
      }
    }).catch(err => {
      this.$notify({title: '错误', message: err, type: 'warning'})
      return false
    })
  }
}

</script>

<style>

</style>