<template>
  <div style="background-color: #F5F5F5">
    <div style="background-color: rgb(179,192,209);height: 60px;">
      <div style=" margin-left: 20px;display: flex;flex-direction: row;">
        <img src="../../img/yh_logo.png"
             style=" width: 30px;height: 30px;padding-right: 10px; margin-top: 15px;margin-left: 120px">
        <div class="pal_logo" style="line-height: 60px;">YH 云平台</div>
      </div>
    </div>
    <div style="margin-top: 30px"></div>
    <div style="margin: 0 15%;text-align: center;width: 70%;">
      <!--产品详情-->
      <el-container>
        <el-header style="background-color: rgb(255,255,255);height: 80px;line-height: 80px;min-width: 1000px">
          <div
              style="margin-left: 40px;font-size: 18px;display: flex;flex-direction: row;justify-content: space-between">
            <div style="display: flex;flex-direction: row;">
              <div @click="goProList()">友好智能安防 / &nbsp;</div>
              <div> {{ product.name }}</div>
            </div>

            <div v-if="product.status !== 2" style="height: 70px">
              <el-button icon="el-icon-position" style="width: 100px" @click="proOnline()"> 发布</el-button>
              <el-button class="el-icon-delete" @click="delProduct()"> 删除</el-button>
            </div>
          </div>
          <div style="height: 1px;background-color: rgb(220,220,220);margin: -10px">
          </div>
        </el-header>

        <el-container>
          <el-aside width="200px" style="background-color:rgb(255,255,255);">
            <el-row class="tac">
              <el-col>
                <el-menu background-color="#fff" :default-active="$route.path" class="el-menu-vertical-demo"
                         text-color="#000" router active-text-color="#F4AD49FF" :default-openeds="openeds">
                  <el-submenu index="1">
                    <template slot="title">
                      <i class="el-icon-tickets"></i>
                      <span style="font-size: 16px">产品信息</span>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item index="/proinfo">基本信息</el-menu-item>
                      <el-menu-item index="/prodatapoint">数据点</el-menu-item>
                      <el-menu-item index="/appconfig">应用配置</el-menu-item>
                      <el-menu-item index="/protemplatemessage">模板消息</el-menu-item>
                      <el-menu-item index="/devicevirtual">虚拟设备</el-menu-item>
                      <el-menu-item index="/devicelog">设备日志</el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>

                  <el-submenu index="2">
                    <template slot="title">
                      <i class="el-icon-s-comment"></i>
                      <span style="font-size: 16px">设备服务</span>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item index="/appdev">应用开发</el-menu-item>
                      <el-menu-item index="/deviceota">OTA升级</el-menu-item>
                      <el-menu-item index="/mcudev">MCU开发</el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>

                  <el-submenu index="3">
                    <template slot="title">
                      <i class="el-icon-setting"></i>
                      <span style="font-size: 16px">信息统计</span>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item index="/devicetotal">概览</el-menu-item>
                      <el-menu-item index="/deviceincrease">新增上线</el-menu-item>
                      <el-menu-item index="/deviceactive">活跃设备</el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                </el-menu>
              </el-col>
            </el-row>
          </el-aside>
          <el-main style="background-color: white;min-width: 1000px;min-height: 820px">
            <router-view>
            </router-view>
          </el-main>
        </el-container>

        <el-footer style="height: 60px;background-color: #F5F5F5;line-height: 60px;margin-top: 20px;font-size: 13px">
          首页 | 隐私声明 | 用户协议 | 关于我们
        </el-footer>
      </el-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'productDetail',

  data() {
    return {
      product: {},
      openeds: ['1', '2', '3'],
      dialogVisible: false
    }
  },

  methods: {
    // 跳转产品列表
    goProList() {
      this.$router.replace("/ProductAllList");
    },

    // 产品上线
    proOnline() {
      this.$confirm("是否发布当前产品？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios.post(this.url + "/product/submitProduct", this.product).then(res => {
          console.log("submitProduct res:", res);
          if (res.data.code !== 200) {
            this.$message.error(res.data.msg);
          } else {
            this.product.status = 2;
            this.$message({
              message: '发布成功',
              type: 'success'
            });
            this.productAllListProd = [];
            this.productAllListDev = [];
            this.isCreateProduct = false;
            this.$axios.get(this.url + "/product/selectProductList").then(res => {
              console.log("selectProductList res:", res);
              if (res.data.code !== 200) {
                this.$message.error(res.data.msg);
              } else {
                for (let i = 0; i < res.data.data.length; i++) {
                  if (res.data.data[i].status === 2) {
                    this.productAllListProd.push(res.data.data[i]);
                  } else {
                    this.productAllListDev.push(res.data.data[i]);
                  }
                }
              }
            }).catch(err => {
              this.$notify({title: '错误', message: err, type: 'warning'})
              return false
            })
          }
        }).catch(err => {
          this.$notify({title: '错误', message: err, type: 'warning'})
          return false
        })
      });
    },


    delProduct() {
      this.$confirm("是否删除当前产品？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios.delete(this.url + "/product/delProduct", {
          params: {id: this.product.id}
        }).then(res => {
          console.log("delProduct res:", res);
          if (res.data.code !== 200) {
            this.$message.error(res.data.msg);
          } else {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.$router.replace("/ProductAllList");
          }
        }).catch(err => {
          this.$notify({title: '错误', message: err, type: 'warning'})
          return false
        })
      });
    },
  },

  mounted() {
    this.product = JSON.parse(sessionStorage.getItem("product"));
  }
}

</script>

<style>

</style>