<template>
  <div class="index">
    待开发...
  </div>
</template>


<script>
export default {
  name: 'appdev',

  data() {
    return {}
  },

  methods: {}
}

</script>

<style>
</style>