<template>
  <div class="index">
    <div class="divHeaderS" style="font-weight: 600">
      <span>OTA升级</span>
    </div>
    <div style="height: 1px;background-color:  rgb(215,215,215)"></div>

    <!-- 提供最新的ota包 设备自行下载完成升级-->
    <div style="margin-top: 30px">
      <el-upload
          class="upload-demo"
          drag
          action="http://yhadmin.u-home-safe.com/device/otaFile/"
          multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div slot="tip" class="el-upload__tip">多次或重复上传，云端仅保留最新上传的一个文件</div>
      </el-upload>
    </div>

    <div style="margin-top: 30px">
      <div style="text-align: left;margin-right: 10px;height: 40px;line-height: 40px">
        1. 请上传最新版本的OTA包到云端
      </div>
      <div style="text-align: left;margin-right: 10px;height: 40px;line-height: 40px">
        2. 设备启动上线，WiFi模组HTTPS GET访问固定URL下载OTA包完成升级
      </div>
      <div style="text-align: left;margin-left: 18px;height: 40px;line-height: 40px">
        https://yhadmin.u-home-safe.com/device/downloadOta
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'deviceota',

  data() {
    return {
      currentRoutePath: "/product",
      mac: "",
      fullscreenLoading: false
    }
  },
  methods: {
  },

  //自动触发写入的函数
  mounted: function () {
  }
}

</script>

<style>
</style>