<template>
  <div class="" style="background-color: white;">
    <div class="div_hight" style="padding-top: 30px"> 产品名称： {{ proDetail.name }}</div>
    <div class="div_hight" style=""> / 产品类型： 安防,监控/家庭安防系统</div>
    <div class="div_hight" style=""> / 技术方案： 单品方案</div>
    <div class="div_hight" style=""> / 通讯方式： Wi-Fi</div>
    <div class="div_hight" style=""> Product Key： {{ proDetail.productKey }}</div>
    <div class="div_hight" style=""> Product Secret：{{ proDetail.productSecret }}</div>
    <div class="div_hight" style=""> / 数据传输方式： 定长</div>
    <div class="div_hight" style=""> 厂商： {{ proDetail.manu }}</div>
    <div class="div_hight" style="">
      创建时间： {{ proDetail.cTime.substring(0, 10) + " " + proDetail.cTime.substring(11, 19) }}
    </div>
    <div class="div_hight" style="">
      更新时间：{{ proDetail.mTime.substring(0, 10) + " " + proDetail.mTime.substring(11, 19) }}
    </div>
    <div class="div_hight" style="padding: 20px 0">
      <el-button size="mini" round style="width: 80px" @click="showModifyPro = true;modifyPro = proDetail">修改
      </el-button>
    </div>
    <el-dialog title="修改产品信息" :visible.sync="showModifyPro" width="40%" center
               style="line-height: 120px;min-width: 800px">
      <el-form ref="form" :model="modifyPro" style="margin-top: -30px;;text-align: center">
        <div style="height: 60px;line-height: 60px;">
          产品名称：
          <el-input v-model="modifyPro.name" style="width: 30%"></el-input>
        </div>
        <div style="height: 60px;line-height: 60px;">
          产品类型：
          <el-input v-model="modifyPro.type" style="width: 30%"></el-input>
        </div>
        <div style="height: 60px;line-height: 60px;">
          产品厂商：
          <el-input v-model="modifyPro.manu" style="width: 30%"></el-input>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModifyPro = false" style="width: 120px">取 消</el-button>
        <el-button type="primary" @click="modifyProduct()" style="width: 120px">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>


<script>

export default {
  name: 'proInfo',

  data() {
    return {
      proDetail: {
        name: "",
        status: "",
        productKey: "",
        productSecret: "",
        cTime: "",
        mTime: "",
        remark: ""
      },
      showModifyPro: false,
      modifyPro: {
        id: "",
        name: "",
        type: "",
        manu: "",
      },
    }
  },
  methods: {
    // 修改产品信息
    modifyProduct() {
      this.modifyPro.id = this.proDetail.id;
      this.$axios.put(this.url + "/product/updateProduct", this.modifyPro).then(res => {
        console.log("updateProduct res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          // 后台返回修改后产品信息
          this.proDetail = res.data.data;
          this.showModifyPro = false;
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },
  },

  //自动触发写入的函数
  mounted: function () {
    this.proDetail = JSON.parse(sessionStorage.getItem("product"));
  }
}

</script>

<style>
.div_hight {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: rgb(50, 50, 50);
}
</style>