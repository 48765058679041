<template>
  <div>
    找回密码
  </div>
</template>

<script>
export default {
  name: "recoverpsw",

  data() {
    return {};
  },
  methods: {}
}
</script>


<style>
</style>
