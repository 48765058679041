import Vue from 'vue'
import VueRouter from 'vue-router'
import productdetail from "@/views/product/ProAside";
import proinfo from "@/views/product/ProInfoDetail";
import fail from "@/views/compent/Fail";
import prolist from "@/views/product/ProAllList";
import prodatapoint from "@/views/product/ProDataPoint";
import devicevirtual from "@/views/device/DeviceVirtual";
import devicelog from "@/views/device/DeviceLog";
import appconfig from "@/views/product/ProAppConfig";
import undev from "@/views/compent/UnDev";
import guidedev from "@/views/application/GuideDev";
import appapi from "@/views/application/AppApi";
import appdev from "@/views/application/AppDev";
import deviceincrease from "@/views/device/DeviceIncrease";
import deviceactive from "@/views/device/DeviceActive";
import mcudev from "@/views/application/McuDev";
import recoverpsw from "@/views/system/RecoverPsw";
import sliderverify from "@/views/compent/SliderVerify";
import devicetotal from "@/views/device/DeviceTotal";
import syslogin from "@/views/system/SysLogin";
import protemplatemessage from "@/views/product/ProTempIdMsg";
import deviceota from "@/views/device/DeviceOta.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/', // 重定向：初始为sysLogin
        redirect: '/syslogin'
    },
    {
        path: '/404',
        name: '/fail',
        component: fail
    },
    {
        path: '/productalllist',
        redirect: '/prolist',
        component: prolist
    },
    {
        path: '/productDetail',
        redirect: '/proinfo',
        component: proinfo
    },
    {
        path: '/syslogin',
        name: '/syslogin',
        component: syslogin
    },
    {
        path: '/prolist',
        name: '/prolist',
        component: prolist
    },
    {
        path: "/sliderverify",
        name: "sliderverify",
        component: sliderverify
    },
    {
        path: "/recoverpsw",
        name: "recoverpsw",
        component: recoverpsw
    },
    {
        path: "/productdetail",
        name: "productdetail",
        component: productdetail,
        children: [
            {
                path: "/proinfo",
                name: "proinfo",
                component: proinfo,
            }, {
                path: "/protemplatemessage",
                name: "protemplatemessage",
                component: protemplatemessage,
            }, {
                path: "/prodatapoint",
                name: "prodatapoint",
                component: prodatapoint,
            }, {
                path: "/devicevirtual",
                name: "devicevirtual",
                component: devicevirtual,
            }, {
                path: "/devicelog",
                name: "devicelog",
                component: devicelog,
            }, {
                path: "/appconfig",
                name: "appconfig",
                component: appconfig,
            }, {
                path: "/undev",
                name: "undev",
                component: undev,
            }, {
                path: "/guidedev",
                name: "guidedev",
                component: guidedev,
            },

            {
                path: "/appdev",
                name: "appdev",
                component: appdev,
            },
            {
                path: "/appapi",
                name: "appapi",
                component: appapi,
            },
            {
                path: "/deviceota",
                name: "deviceota",
                component: deviceota,
            },
            {
                path: "/mcudev",
                name: "mcudev",
                component: mcudev,
            },

            {
                path: "/deviceactive",
                name: "deviceactive",
                component: deviceactive,
            },

            {
                path: "/deviceincrease",
                name: "deviceincrease",
                component: deviceincrease,
            },
            {
                path: "/devicetotal",
                name: "devicetotal",
                component: devicetotal,
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    routes
})


// 为路由对象 挂载路由导航守卫
// router.beforeEach((to, from, next) => {
//     // to 将要访问的的路径
//     // from 代表从哪个路径跳转
//     // next 是一个函数 表示放行 next() 放行 next('/login') 强制跳转
//     //如果用户访问的登录页面，直接放行
//     if (to.path === '/sysLogin') {
//         return next()
//     }
//     if (to.path === '/forget') {
//         return next()
//     }
//     if (to.path === '/register') {
//         return next()
//     }
//     // 从sessionStorage中获取到保存的 token值
//     const tokenStr = window.sessionStorage.getItem('isLogin')
//     // console.log("tokenStr:", tokenStr)
//     //没有token 强制跳转到登录页
//     if (!tokenStr) {
//         alert("请先登录")
//         return next('/sysLogin')
//     }
//     next()
// })


export default router
