<template>
  <div>
    <div class="divHeaderS" style="font-weight: 600">
      <span v-if="isStartVeriDevice"> 虚拟设备</span>
      <span v-if="!isStartVeriDevice"> <span style="color: #8c939d"> 虚拟设备 /  </span>在线调试</span>
    </div>
    <div style="height: 1px;background-color: rgb(215,215,215)"></div>

    <div v-if="isStartVeriDevice">
      <img src="../../img/vir_device.jpg" style="margin-top: 120px" alt="加载失败">
      <div style="height: 30px;line-height: 30px;font-size: 14px">
        通过虚拟设备模拟真实设备上报数据的行为，可以快速验证接口功能的开发。
      </div>
      <div style="height: 30px;line-height: 30px;font-size: 14px">
        可以使用接口绑定虚拟设备，即可对虚拟设备进行远程控制及查看通讯日志。
      </div>
      <el-button @click="startVirDevice()" style="margin-top: 30px">启动虚拟设备</el-button>
    </div>

    <div v-if="!isStartVeriDevice">
      <div
          style="border:1px solid  rgb(215,215,215);text-align: left;padding: 20px;margin-top: 15px;">
        <div style="display: flex;flex-direction: row;height: 90px">
          <div>
            <div style="height: 30px;line-height: 30px;font-size: 15px;">
              <div style="font-weight: 600">ProductKey: {{ proDetail.productKey }}</div>
            </div>
            <div style="height: 30px;line-height: 30px;font-size: 15px">
              虚拟设备的mac: {{ virtualDeviceInfo.mac }}，did：{{
                virtualDeviceInfo.did
              }}，passcode：{{ virtualDeviceInfo.passcode }}
            </div>
            <div style="height: 30px;line-height: 30px;font-size: 15px; display: flex;flex-direction: row;">
              <div>通过微信小程序扫描二维码绑定虚拟设备</div>
              <div style="margin-left: 10px">
                <el-button @click="showDeviceQrcode()" icon="el-icon-c-scale-to-original" style="border-radius: 20px;
                background-color: #FFFFFF;border:1px solid rgb(64,154,255);
                    color: #000000;padding: 4px 10px">显示二维码
                </el-button>
              </div>
            </div>
          </div>
          <div style="margin-left: 70px;">
            <div class="qrcode" ref="qrCodeUrl"></div>
          </div>
        </div>
      </div>

      <div style="display: flex;flex-direction: row;justify-content: space-between;margin-top: 15px;height: 500px">
        <el-col :span="12" style="border:1px solid  rgb(215,215,215);padding: 20px;margin-right: 7px;">
          <div style="background-color: #ffffff;height: 400px;overflow:auto;">
            <div v-for="(pro,index) in proDataPointList" :key="index"
                 style="display: flex;flex-direction: row;justify-content: space-between">
              <div class="pdp_hight">
                {{ pro.enName }}
              </div>
              <div v-if="pro.dataType === '布尔值'" class="pdp_hight" style="width: 140px;margin-right: 10px">
                <el-select v-model="booleanValue[index]">
                  <el-option v-for="item in [0,1]" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </div>
              <div v-if="pro.dataType === '枚举'" class="pdp_hight" style="width: 140px;margin-right: 10px">
                <el-select v-model="enumValue[index]">
                  <el-option v-for="item in pro.enumValue.split(',')" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </div>
              <div v-if="pro.dataType === '数值'" class="pdp_hight"
                   style="width: 220px;margin-right: 11px">
                <el-slider style="margin-top: 10px" v-model="sliderValue[index]" show-input input-size="large"
                           :step="Number(pro.increase)"
                           :min="Number(pro.minValue)"
                           :max="Number(pro.maxValue)"
                >
                </el-slider>
              </div>
            </div>
          </div>
          <div style="height: 80px;line-height: 80px;">
            <el-button @click="sendMessage()"
                       style="border-radius: 20px;width: 100px;height: 30px;background-color: rgb(64,158,255);
                    color: white;padding-bottom: 25px">发送
            </el-button>
          </div>
        </el-col>

        <el-col :span="12" style="border:1px solid  rgb(215,215,215);padding: 20px;margin-left: 7px">
          <div style="background-color: #ffffff;height: 400px;text-align: left;overflow:auto;">
            <div v-for="(pro,index) in logList" :key="index" style="line-height: 20px;">
              <pre>{{ pro }}</pre>
            </div>
          </div>
          <div style="height: 80px;line-height: 80px;">
            <el-button @click="clearnLog()"
                       style="border-radius: 20px;width: 100px;height: 30px;;
                    color:  rgb(215,215,215);padding-bottom: 25px;">清空
            </el-button>
          </div>
        </el-col>
      </div>
    </div>
  </div>
</template>


<script>
let mqtt = require('mqtt');
import QRCode from 'qrcodejs2';

let client = null;

export default {
  name: 'devicevirtual',

  data() {
    return {
      isStartVeriDevice: true,
      proDetail: null,
      proDataPointList: null,
      booleanOptions: [{
        booleanValue: '0',
        label: '0'
      }, {
        booleanValue: '1',
        label: '1'
      }],
      // 虚拟设备信息
      virtualDeviceInfo: {
        mac: "",
        did: "YH00000000",
        passcode: "123456"
      },
      // MQTT clientInfo
      mqttClientInfo: {
        port: 8083,
        cleanSession: true,
        connectTimeout: 600, // 超时时间
        clientId: "",
        username: 'admin',
        password: 'admin',
      },
      mqttTopic: "yh",
      mqttUrl: "ws://115.159.158.183/mqtt",
      booleanValue: [],
      enumValue: [],
      sliderValue: [],
      logList: [{"log": "start======"}],
      isDeviceQrcodeImg: false,
      hexStr: "",
    }
  },

  methods: {
    // 显示二维码 显示的情况下，不再生成
    showDeviceQrcode() {
      console.log("showDeviceQrcode:",this.isDeviceQrcodeImg)
      console.log("showDeviceQrcode:",this.virtualDeviceInfo.mac)
      if (this.isDeviceQrcodeImg) {
        return;
      }
      this.isDeviceQrcodeImg = true;
      new QRCode(this.$refs.qrCodeUrl, {
        text: this.virtualDeviceInfo.mac,
        width: 90,
        height: 90,
        colorDark: '#000000',
        colorLight: '#ffffff'
      })
    },

    // 推送信息
    sendMessage() {
      const pointJson = {};
      for (let i = 0; i < this.proDataPointList.length; i++) {
        if (this.booleanValue[i] !== undefined) {
          pointJson[this.proDataPointList[i].enName] = this.booleanValue[i];
        }
        if (this.enumValue[i] !== undefined) {
          pointJson[this.proDataPointList[i].enName] = this.enumValue[i];
        }
        if (this.sliderValue[i] !== undefined) {
          pointJson[this.proDataPointList[i].enName] = this.sliderValue[i];
        }
      }
      const dataJson = {};
      dataJson.productKey = this.proDetail.productKey;
      dataJson.mac = this.virtualDeviceInfo.mac;
      dataJson.dataPoint = pointJson;
      dataJson.timeStamp = new Date().getTime();
      dataJson.clientId = this.mqttClientInfo.clientId;
      client.publish("yh", JSON.stringify(dataJson));
    },
    // 清除日志
    clearnLog() {
      this.logList = [];
    },

    // 开始虚拟设备调试
    startVirDevice() {
      var that = this;
      this.$axios.get(this.url + "/proDataPoint/selectProductPoint" + "?productKey=" + this.proDetail.productKey).then(res => {
        console.log("proDataPointAll res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.proDataPointList = res.data.data; // 数据点集合
          this.isStartVeriDevice = false;
          //mqtt链接
          console.log("client:", client)
          if (client == null) {
            client = mqtt.connect(this.mqttUrl, this.mqttClientInfo);
          }
          // 成功连接后触发的回调
          client.on('connect', () => {
            client.subscribe(this.mqttTopic, 0);
            console.log('connect succ, subscribe：', this.mqttTopic);
          });
          // 连接断开后触发的回调
          client.on("close", function () {
            console.log("connect close, forbid reconnect!");
            client.end();
          });
          client.on("error", function (res) {
            console.log("error res:", res);
            that.$message({
              message: '设备未连接，请刷新后重新启动',
              type: 'warning'
            });
          });
          /**
           * mqtt.Client相关事件
           */
          client.on('message', (topic, message) => {
            console.log(`receive msg ${message} from topic ${topic}`, this.virtualDeviceInfo.mac);
            // TODO 如果是buffer格式会报错，需处理； message && typeof message === "object" && Buffer.isBuffer(message)
            if (JSON.parse(message).mac !== this.virtualDeviceInfo.mac) {
              return;
            }
            this.logList.unshift(JSON.parse(message));
            this.logList.unshift("---time:" + new Date().toLocaleString());
          });
          //当客户端无法连接或出现错误时触发回调
          client.on("error", (error) => {
            console.log("error:", error)
          });
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },
  },

  //自动触发写入的函数
  mounted: function () {
    this.proDetail = JSON.parse(sessionStorage.getItem("product"));
    // 初始化虚拟设备、客户端信息
    this.mqttClientInfo.clientId = this.proDetail.mark;
    this.virtualDeviceInfo.mac = this.proDetail.mark;
  },

  destroyed: function () {
    // if (client != null) {
    //   client.disconnect();
    // }
    // console.log("page unload:", client)
  },

}

</script>

<style>
.divHeaderS {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  display: flex;
  margin-top: -10px
}

</style>