import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import 'vue-layer-mobile/need/layer.css'
import layer from 'vue-layer-mobile'
import url from './router/api'
import SlideVerify from 'vue-monoplasty-slide-verify'

Vue.use(layer)
Vue.prototype.$axios = axios
Vue.use(ElementUI);
Vue.use(SlideVerify);
Vue.config.productionTip = false
Vue.prototype.url = url.url;


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
